<template>
  <div>
    <b-card>
      <b-row>
        <b-col lg="8">
          <b-input-group>
            <b-input-group-prepend is-text size="sm">
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input placeholder="Procurar" size="sm" v-model="search" debounce="800" type="search" :disabled="isLoading"/>
          </b-input-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <b-table-simple striped responsive>
        <b-thead>
          <b-tr class="text-center">
            <b-th>Tipo</b-th>
            <b-th>Status</b-th>
            <b-th>Log</b-th>
            <b-th>Produto</b-th>
            <b-th>Data de Importação</b-th>
            <b-th>Baixar arquivo</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            :key="indextr"
            v-for="(tr, indextr) in modules.historicos"
            class="text-center"
          >
            <b-td>
              <b v-if="tr.tipo == 'colaboradores'">Colaboradores</b>
              <b v-if="tr.tipo == 'produtos'">Produtos</b>
              <b v-if="tr.tipo == 'outros'">Outros</b>
            </b-td>
            <b-td>
              <b-badge
                variant="success"
                class="badge-glow"
                v-if="tr.log_error.length == 0"
              >
                Sem erros
              </b-badge>
              <b-badge
                variant="danger"
                class="badge-glow"
                v-if="tr.log_error.length > 0"
              >
                Com erros
              </b-badge>
            </b-td>
            <b-td>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                pill
                size="sm"
                :disabled="tr.log_error.length == 0"
                @click="loadingLog(tr.log_error)"
                v-b-modal.modal-lg
              >
                Visualizar Log
              </b-button>
            </b-td>
            <b-td>
              {{ tr.produto_id == null ? "Nada Informado" : tr.produto.nome }}
            </b-td>
            <b-td class="text-center">
              {{ tr.created_at | dateTimeFormat }}
            </b-td>
            <b-td class="text-center">
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="flat-dark"
                class="btn-icon rounded-circle"
              >
                <feather-icon icon="DownloadIcon" />
              </b-button>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <b-pagination
        v-model="currentPage"
        class="text-center float-right"
        :per-page="perPage"
        :total-rows="totalRows"
        aria-controls="tableSchools"
        size="sm"
        @change="handlePageChange"
      />
      <b-modal
        id="modal-lg"
        ok-only
        ok-title="Fechar"
        centered
        size="xl"
        title="Relatório de Erro"
      >
        <b-table-simple striped responsive>
          <b-thead>
            <b-tr class="text-center">
              <b-th>Linha</b-th>
              <b-th>Colaborador</b-th>
              <b-th>Data de Importação</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr
              :key="indextr"
              v-for="(tr, indextr) in logData"
              class="text-center"
            >
              <b-td>
                {{ tr.linha_error }}
              </b-td>
              <b-td>
                {{ tr.colaborador }}
              </b-td>
              <b-td>
                {{ tr.created_at | dateTimeFormat }}
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-modal>
    </b-card>
  </div>
</template>
<script>
/* eslint-disable */
import { mapActions, mapState } from "vuex";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    mapActions,
    mapState,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // Variáveis Global
      isLoading: true,

      logData: null,

       search: null,

      perPage: 9,
      currentPage: 1,
      totalRows: null,
    };
  },
  computed: {
    ...mapState("connectAPI", ["modules"]),
  },
  methods: {
    fetchModules(paginated = true, page = 1) {
      this.isLoading = true;
      this.$store
        .dispatch("connectAPI/fetchModules", {
          pathAPI: "historicos",
          paginated: paginated,
          page: page,
          generic: this.search,
          per_page: this.perPage,
        })
        .then((response) => {
          this.totalRows = response.total;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    handlePageChange(value) {
      this.fetchModules(true, value);
    },

    loadingLog(value) {
      this.logData = null;
      this.logData = value;
    },
  },
  created() {
    this.fetchModules();
  },
  watch: {
    perPage() {
      this.fetchModules();
    },
    search(){
      this.currentPage = 1;
      this.fetchModules(true, 1);
    }
  },
};
</script>
<style lang="scss">
</style>
